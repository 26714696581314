import React from 'react';

const EmbeddedSpotify = (props) => {
    const {link} = props
    return (
        <>
            <iframe
                src={link} width="100%"
                height="352" frameBorder="0" allowFullScreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"></iframe>
        </>
    );
};

export default EmbeddedSpotify;