import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import EmbeddedPage from "./pages/EmbeddedPage";


function App() {
    return (
        <>
            <Router>
                <Routes>
                    {/* Bohemian Rhapsody */}
                    <Route path="/bohemian-rhapsody" element={<EmbeddedPage
                        spotify={"https://open.spotify.com/embed/track/3z8h0TU7ReDPLIbEnYhWZb?utm_source=generator"}
                        youtube={"https://www.youtube.com/embed/fJ9rUzIMcZQ?si=tCS6uPlgR0mcFLFd&amp;clip=Ugkxx3BCoJBWCV8L023Oy0zaT2Ad_TT33zs0&amp;clipt=ENnDCRjXrgs"}/>
                    }/>
                    <Route path="/shape-of-you" element={<EmbeddedPage
                        spotify={"https://open.spotify.com/embed/track/7qiZfU4dY1lWllzX7mPBI3?utm_source=generator"}
                        youtube={"https://www.youtube.com/embed/JGwWNGJdvx8?si=YLd9mmAEVCETlFbk&amp;clip=UgkxEvOcYo_EnFiXMyUSgh5sZPJ89bmCw1w0&amp;clipt=EJGnCRjBkQs"}/>
                    }/>
                    <Route path="/il-pescatore" element={<EmbeddedPage
                        spotify={"https://open.spotify.com/embed/track/0i4mLwfyxnSSgF6hwIkU2L?utm_source=generator"}
                        youtube={"https://www.youtube.com/embed/X_kuqsezS24?si=j_FQ0gcRfLiOlOgf&amp;clip=Ugkx-0NOIXruQ9hV9Qqgf5IrqCaX0ArUHiaH&amp;clipt=EPK9BhihqAg"}/>
                    }/>

                    <Route path="/the-lazy-song" element={<EmbeddedPage
                        spotify={"https://open.spotify.com/embed/track/386RUes7n1uM1yfzgeUuwp?utm_source=generator"}
                        youtube={"https://www.youtube.com/embed/fLexgOxsZu0?si=L-RksoCveQYqQ6ac&amp;clip=UgkxVKr91Y5e0OMiow44mkEPeHrczISjUEkg&amp;clipt=EI7_Bxi-6Qk"}/>
                    }/>
                    <Route path="/non-me-lo-posso-permettere" element={<EmbeddedPage
                        spotify={"https://open.spotify.com/embed/track/6HqJfzaStS9m7ln9Ec1ZK2?utm_source=generator"}
                        youtube={"https://www.youtube.com/embed/umUmQgkgFT8?si=ZcpK__ctNLUmnhob&amp;clip=Ugkxv8p1t5Juy0DFHjRDwpwx6qoaYsZpPjuH&amp;clipt=EIHzBBix3QY"}/>
                    }/>
                </Routes>
            </Router>

        </>
    );
}

export default App;
