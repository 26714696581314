import React from 'react';

const EmbeddedYoutube = (props) => {
    const {link} = props
    return (
        <>
            <iframe width="100%" height="315"
                    src={link}
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
        </>
    );
};

export default EmbeddedYoutube;