import React from 'react';
import styles from './EmbeddedPage.module.css'
import {EmbeddedSpotify, EmbeddedYoutube} from "../components";

const EmbeddedPage = (props) => {
    const {spotify, youtube} = props
    return (
        <>
            <div className={styles.container}>
                <div className={styles.section}>

                    <EmbeddedSpotify link={spotify}/>
                    <br/>
                    <EmbeddedYoutube link={youtube}/>
                </div>
            </div>

        </>
    );
};

export default EmbeddedPage;